import React from 'react';
import { render } from 'react-dom';

import App from './app';
import { NytProvider } from './nyt';
import { StoreProvider } from './store';

render(
  (
    <StoreProvider>
      <NytProvider>
        <App />
      </NytProvider>
    </StoreProvider>
  ),
  document.getElementById('app-root'),
);
