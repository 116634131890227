import React, {
  createContext,
  FC,
  useEffect,
  useState,
} from 'react';

interface GameData {
  today: {
    validLetters: string[];
  };
}

export interface NytData {
  letters: string[];
}

export const Context = createContext<NytData>({ letters: [] });

export const NytProvider: FC = ({ children }) => {
  const [nytData, setNytData] = useState({ letters: [] } as NytData);

  useEffect(() => {
    fetch('/api/game-data/latest')
      .then((res) => res.json() as Promise<GameData>)
      .then((body) => {
        setNytData({ letters: body.today.validLetters.map((x) => x.toLocaleUpperCase('en')) });
      })
      .catch(console.error);
  });

  return (
    <Context.Provider value={nytData}>
      {children}
    </Context.Provider>
  );
};
