import twl06 = require('./twl06.json');

export const isPangram = (letters: string[], word: string): boolean => {
  const chars = word.split('');
  return letters.every((letter) => chars.includes(letter));
};

export const scoreWord = (letters: string[], words: string[], word: string): number => {
  if (word.length < 4 || words.includes(word) || !word.split('').includes(letters[0])
    || !twl06.includes(word.toLowerCase())) {
    return 0;
  }
  if (word.length === 4) {
    return 1;
  }
  if (isPangram(letters, word)) {
    return word.length + 7;
  }
  return word.length;
};
