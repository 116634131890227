import React, {
  FunctionComponent,
  useContext,
} from 'react';

import GameView from './game-view.tsx';
import SetupView from './setup-view';
import { StoreContext } from './store';

const App: FunctionComponent = () => {
  const { state } = useContext(StoreContext);

  const { view } = state;

  return (
    <div className="mx-auto max-w-sm min-h-screen p-2 bg-white shadow-lg">
      {view === 'GAME' && (
        <GameView />
      )}
      {view === 'SETUP' && (
        <SetupView />
      )}
    </div>
  );
};

export default App;
