import React, {
  FunctionComponent,
  useContext,
} from 'react';

import Honeycomb from './honeycomb';
import { isPangram } from './scoring';
import { StoreContext } from './store';

const GameView: FunctionComponent = () => {
  const { dispatch, state } = useContext(StoreContext);

  const {
    currentWord,
    letters,
    score,
    words,
  } = state;

  return (
    <>
      <div className="mb-6 flex justify-end items-center">
        <button
          className="px-6 py-4 text-xl"
          onClick={() => dispatch({ type: 'SWITCH_VIEW', payload: 'SETUP' })}
          type="button"
        >
          ☰
        </button>
      </div>
      <div className="my-6 flex justify-center">
        <div
          className="w-8 h-8 rounded-full bg-yellow-400 text-xs flex items-center justify-center"
        >
          <div>
            {score}
          </div>
        </div>
      </div>
      <div
        className="m-6 px-2 py-1 border border-gray-200 rounded font-light text-sm flex flex-wrap"
      >
        {words.length === 0 && (
          <div className="m-1 text-gray-400">
            Your words...
          </div>
        )}
        {words.map((word) => (
          <div
            className={`m-1 ${isPangram(letters, word) ? 'text-yellow-400' : ''}`}
            key={word}
          >
            {word}
          </div>
        ))}
      </div>
      <div className="mb-6 mt-16 h-7 font-bold text-center text-lg">
        {currentWord.split('').map((ch, i) => (
          <span
            className={ch === letters[0] ? 'text-yellow-400' : undefined}
            key={i /* eslint-disable-line react/no-array-index-key */}
          >
            {ch}
          </span>
        ))}
        <span className="text-yellow-400 animate-pulse text-2xl font-light relative">
          |
        </span>
      </div>
      <Honeycomb />
      <div className="my-6 flex justify-center items-center space-x-3">
        <button
          className="h-10 w-20 rounded-full border border-gray-200 text-sm"
          onClick={() => dispatch({ type: 'DELETE_LETTER' })}
          type="button"
        >
          Delete
        </button>
        <button
          className="h-10 w-10 rounded-full border border-gray-200 text-xl pb-1"
          onClick={() => dispatch({ type: 'SHUFFLE_LETTERS' })}
          type="button"
        >
          ⟳
        </button>
        <button
          className="h-10 w-20 rounded-full border border-gray-200 text-sm"
          onClick={() => dispatch({ type: 'ENTER_WORD' })}
          type="button"
        >
          Enter
        </button>
      </div>
    </>
  );
};

export default GameView;
