import React, {
  ChangeEventHandler,
  FunctionComponent,
  useContext,
  useEffect,
} from 'react';

import { Context as NytContext } from './nyt';
import { StoreContext } from './store';

const range7 = [0, 1, 2, 3, 4, 5, 6];

const isValidLetter = (ch: string): boolean => ch.length === 1 && ch >= 'A' && ch <= 'Z';

const SetupView: FunctionComponent = () => {
  const {
    dispatch,
    state: { letters },
  } = useContext(StoreContext);

  const { letters: nytLetters } = useContext(NytContext);

  useEffect(() => {
    const firstInput = document.getElementById('setup-view-letter-selector-0');
    if (firstInput) {
      firstInput.focus();
    }
  }, []);

  const handleLetterChange = (i: number): ChangeEventHandler<HTMLInputElement> => (ev) => {
    let newLetter = ev.currentTarget.value.slice(-1).toUpperCase();
    if (letters.length > i && newLetter === letters[i]) {
      newLetter = ev.currentTarget.value[0].toUpperCase();
    }
    if (isValidLetter(newLetter)) {
      let idx = letters.indexOf(newLetter);
      if (idx === -1) {
        idx = i;
      }
      const newLetters = [...letters];
      if (newLetters.length > idx) {
        newLetters.splice(idx, 1, newLetter);
      } else {
        newLetters.push(newLetter);
      }
      dispatch({ type: 'SET_LETTERS', payload: newLetters });
      let nextIdx = idx + 1;
      if (nextIdx > newLetters.length) {
        nextIdx = newLetters.length;
      }
      nextIdx = Math.min(nextIdx, 6);
      const nextInput = document.getElementById(`setup-view-letter-selector-${nextIdx}`);
      if (nextInput) {
        nextInput.focus();
      }
    }
  };

  return (
    <div>
      <div className="flex items-center justify-between">
        <button
          className="p-3 text-lg disabled:text-gray-400"
          disabled={letters.length !== 7}
          onClick={() => dispatch({ type: 'SWITCH_VIEW', payload: 'GAME' })}
          type="button"
        >
          ✕
        </button>
      </div>
      <h2 className="mt-6 text-center text-gray-600 font-light">
        Choose letters:
      </h2>
      <div className="my-3 flex justify-center space-x-2">
        {range7.map((i) => (
          <input
            className={`
              w-10 py-2 appearance-none outline-none
              border-2 ${i === 0 ? 'border-yellow-400' : 'border-gray-200'}
              rounded text-center
            `}
            key={i}
            id={`setup-view-letter-selector-${i}`}
            onChange={handleLetterChange(i)}
            type="text"
            value={letters.length > i ? letters[i] : ''}
          />
        ))}
      </div>
      <button
        className={`
          w-full my-6 px-4 py-2 rounded font-bold text-sm
          bg-yellow-400 hover:bg-yellow-500 transition-all
          ${nytLetters.length === 7 ? 'bg-yellow-400 cursor-pointer' : 'bg-gray-400 cursor-default'}
        `}
        disabled={nytLetters.length !== 7}
        onClick={() => dispatch({ type: 'SET_LETTERS', payload: nytLetters })}
        type="button"
      >
        {nytLetters.length === 7 ? 'Populate from NYT' : 'Loading from NYT...'}
      </button>
      <button
        className={`
          w-full my-6 px-4 py-2 rounded font-bold text-sm
          bg-yellow-400 hover:bg-yellow-500 transition-all
          ${letters.length < 7 ? 'opacity-0 cursor-default' : 'cursor-pointer'}
        `}
        disabled={letters.length !== 7}
        onClick={() => dispatch({ type: 'SWITCH_VIEW', payload: 'GAME' })}
        type="button"
      >
        Play Built to Spell
      </button>
    </div>
  );
};

export default SetupView;
