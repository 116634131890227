import React, { SFC } from 'react';

interface Props {
  accent?: boolean;
  onClick(): void;
}

const Hexagon: SFC<Props> = ({ accent, children, onClick }) => (
  <div className="hexagon">
    <div>
      <button
        className={`${accent ? 'bg-yellow-400' : 'bg-gray-200'} font-bold text-xl`}
        onClick={onClick}
        type="button"
      >
        {children}
      </button>
    </div>
  </div>
);

export default Hexagon;
