import React, {
  CSSProperties,
  FunctionComponent,
  useContext,
} from 'react';

import Hexagon from './hexagon';
import { StoreContext } from './store';

const hexagonSizePx = 60;

const positions: CSSProperties[] = [{
  position: 'absolute',
  marginLeft: hexagonSizePx * 1.1,
  marginTop: hexagonSizePx * 1.0,
  visibility: 'hidden',
}];
for (let i = 0; i < 6; i += 1) {
  positions.push({
    position: 'absolute',
    marginLeft: hexagonSizePx * (1.1 + 1.1 * Math.cos((Math.PI * (i + 0.5)) / 3)),
    marginTop: hexagonSizePx * (1.0 + 1.1 * Math.sin((Math.PI * (i + 0.5)) / 3)),
    visibility: 'hidden',
  });
}

const containerStyle: CSSProperties = {
  margin: '0 auto',
  height: hexagonSizePx * 3.2,
  position: 'relative',
  width: hexagonSizePx * 3.2,
};

const Honeycomb: FunctionComponent = () => {
  const { dispatch, state } = useContext(StoreContext);

  const { letters } = state;

  if (letters.length !== 7) {
    return (
      <div>
        Waiting for letters...
      </div>
    );
  }

  return (
    <div
      className="mx-auto"
      style={containerStyle}
    >
      {letters.map((ch, i) => (
        <div
          key={ch}
          style={positions[i]}
        >
          <Hexagon
            accent={i === 0}
            onClick={() => dispatch({ type: 'ENTER_LETTER', payload: ch })}
          >
            {ch}
          </Hexagon>
        </div>
      ))}
    </div>
  );
};

export default Honeycomb;
